<template>
  <el-table :data="tableData" border style="width: 100%">
    <el-table-column prop="ip" label="ip" width="120"> </el-table-column>
    <el-table-column prop="address" label="地区"> </el-table-column>
    <el-table-column prop="system" label="系统"> </el-table-column>
    <el-table-column prop="browser" label="浏览器"> </el-table-column>
    <el-table-column prop="reason" label="原因"> </el-table-column>
    <el-table-column prop="insertTime" label="时间" width="160">
    </el-table-column>
    <el-table-column label="操作" width="100">
      <template slot-scope="scope">
        <el-button @click="edit(scope)" type="text" size="small"
          >移除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },
  created() {
    const that = this;
    this.getData(that);
  },
  methods: {
    getData(that) {
      this.$axios.get("/adminApi/blakVisitors").then(function (resp) {
        resp.data.data.forEach((element) => {
          element.insertTime = that.common.dateFormatter(element.insertTime);
        });
        that.tableData = resp.data.data;
      });
    },
    edit(scope) {
      let that = this;
      this.$axios
        .get("/adminApi/delBlackVisitor?ip=" + scope.row.ip)
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.$notify.success({
              title: resp.data.msg,
            });
            that.getData(that);
          } else {
            that.$notify.error({
              title: resp.data.msg,
            });
          }
        });
    },
  },
};
</script>

<style>
</style>